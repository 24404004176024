<template>
    <div style="display: flex; justify-content: space-between;">
        <div style="display: flex; justify-content: space-between;">
            <div class="headerTabsDiv body-text font-weight-medium text-transform-none-class" v-for="headerTab, index in headerTabs" :key="index" @click="redirectTab(headerTab)">
            <button 
                class="tabButton clickable-pointer"
                :style="{ borderBottom: (tab_id === index) ? '2px solid #1976d2' : 'none', color: (tab_id === index) ? '#1976d2' : 'none'}"
                >
                {{ headerTab.tab_name }}
            </button>
            </div>
        </div>
    
        <div v-if="is_user_logged_in" class="mr-5 px-1 py-1 my-orders-segment">
            <button class="px-2 py-1 body-text font-weight-medium rounded light-blue-background white-color-text my-orders" @click="redirectTo('MyOrdersPage')">{{ $t("Customer.MyOrders.My_Orders") }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderTabsComponent',
    props: {
        tab_id: {
            type:  [Number, String],
            required: true
        },
        is_user_logged_in: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            customerToken: '',
            headerTabs: []
        };
    },
    mounted() {
        this.activeTab = this.tab_id;

        this.headerTabs = [
            {
                tab_name: 'Home',
                redirection_link: '/'
            }, {
                tab_name: 'Pharmacy',
                redirection_link: '/customer/ePharmacyHome'
            }, {
                tab_name: 'Telemedicine',
                redirection_link: '/customer/teleconsultationHome',
                new_tab: true
            }, {
                tab_name: 'Health Articles',
                redirection_link: '',
                new_tab: true
            }
        ];
    },
    methods: {
        redirectTab(headerTab) {
            if (headerTab.new_tab) {
                if (headerTab.redirection_link == '/customer/ePharmacyHome') {
                    window.open('https://mymedicine.com.mm/shop', '_blank');
                } else {
                    window.open(window.location.origin + headerTab.redirection_link, '_blank');
                }
            } else {
                if (headerTab.redirection_link == '/customer/ePharmacyHome') {
                    window.open('https://mymedicine.com.mm/shop', '_blank');
                } else {
                    this.$router.push({
                        path: headerTab.redirection_link
                    });
                }
            }
        },
        redirectTo(pageName) {
            this.$router.push({
                name: pageName
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.my-orders-segment {
    display: flex;
}
.headerTabsDiv {
    display: flex !important;
    flex-direction: column !important;
    justify-content: left !important;
}
.tabButton {
    padding: 10px;
    color: rgba(0,0,0,.54);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0px !important;
}
.my-orders {
    min-width: 90px;
}
</style>
